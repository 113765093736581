<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.version.crud.create }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onProfileSubmit">
              <CRow>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.version.form.appType }}
                      <required_span/>
                    </label>
                    <v-select id="appType" :options="appTypeOptions" v-model="version.appType"></v-select>
                    <small class="error" v-if="$v.version.$error && !$v.version.appType.required">{{ $lang.version.validation.required.appType }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.version.form.version }}
                      <required_span/>
                    </label>
                    <input :maxlength="maxlength.version" :placeholder="$lang.version.form.version" class="form-control" v-model="version.version"/>
                    <small class="error" v-if="$v.version.$error && !$v.version.version.required">{{ $lang.version.validation.required.version }}</small>
                  </div>
                </CCol>
                <CCol md="3">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.version.form.force_update }} </label><br>
                    <input type="checkbox" :placeholder="$lang.version.form.force_update" :value="0" @change="changedIsNewFeature" v-model="version.forceUpdate"/>
                  </div>
                </CCol>
                <CCol md="3">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.version.form.is_active }} </label><br>
                    <input type="checkbox" :placeholder="$lang.version.form.is_active" :value="0" @change="changedActive" v-model="version.isActive"/>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6" class="text-left">
                  <CButton color="primary" :disabled="submitted" class="px-4" type="submit">
                    {{ $lang.common.general.submit }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {version} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "../../store/maxlength";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "CreateVersion",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      module: version,
      msg: "",
      appTypeOptions: [
        {
          'id': '0',
          'label': 'Web',
        },
        {
          'id': '1',
          'label': 'Android',
        },
        {
          'id': '2',
          'label': 'IOS',
        },
      ],
      version: {
        version: "",
        forceUpdate: 0,
        isActive: 1,
        appType: "",
      },
      maxlength: {
        version: Maxlength.version.version,
      },
    };
  },
  validations: {
    version: {
      appType: {
        required,
      },
      version: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.version.isActive = 1;
      } else {
        self.version.isActive = 0;
      }
    },
    changedIsNewFeature(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.version.isNewFeature = 1;
      } else {
        self.version.isNewFeature = 0;
      }
    },
    onProfileSubmit() {
      let self = this;
      this.$v.version.$touch();
      if (this.$v.version.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          "version": self.version.version,
          "isActive": self.version.isActive,
          "appType": self.version.appType.id,
          "forceUpdate": self.version.forceUpdate,
        }
        axios.post(this.createUrlWeb(this.module), postData)
            .then((response) => {
              self.submitted = false; //Enable Button
              store.commit("showLoader", false); // Loader Off
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem("notificationMessage", response.data.message);
                this.$router.push({name: "Version"});
                self.dismissCountDown = 10;
                self.messageColor = "success";
              } else {
                self.err_msg = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDown = 10;
              }
            }).catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.messageColor = "danger";
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
        });
      }
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
